import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Container, Dropdown, Grid, Header, Menu, Message, Modal, Segment, Table } from "semantic-ui-react";
import { getAllGroups } from "../features/groups/groupSlice";
import { createMember, getMembers } from "../features/members/memberSlice";
import { getApplications } from "../features/survey/surveySlice";

const Transfer = () => {
    const dispatch = useDispatch();

    const { applications, isLoading: isLoadingSrv } = useSelector(state => state.surveys);
    const { groupsArr, isLoading: isLoadingGrp } = useSelector((state) => state.groups);
    const { membersArr, isLoading: isLoadingMem } = useSelector((state) => state.members);


    const [selectedGroup, setSelectedGroup] = useState("");
    const [selectedApplicants, setSelectedApplicants] = useState([]);
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        dispatch(getApplications());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAllGroups());
    }, [dispatch]);

    const selectGroup = async (group) => {
        setSelectedGroup({ groupname: group.groupname, id: group.id});
        await dispatch(getMembers(group.id)).unwrap();
    }

    // Filter out the applications that are already members, the applicant info will not be deleted, so it needs to be filtered out
    const filteredApplications = applications.filter(application => !membersArr.some(member => member.id === application.data.id));
    
    // Get the facilitator ids from the first member of the group
    const facilitatorIds = membersArr ? membersArr[0]?.facilitator : null;
    
    const toggleApplicantSelection = (application) => {
        const user = {
            id: application.data.id,
            name: application.data.name.trim(),
            lastname: application.data.lastname.trim(),
            groupid: selectedGroup.id,
            email: application.data.email.toLowerCase(),
            instemail: application.data.instemail,
            edulevel: application.data.edulevel === "Bachelor's Degree" ? 'Bsc' : application.data.edulevel === "Master's Degree" ? 'Msc' : 'Phd',
            facilitator: facilitatorIds,
            role: 'participant',
            image: 0,
            username: `${application.data.name.trim().toLowerCase()}.${application.data.lastname.trim().replace(/\s+/g, '').toLowerCase()}`,
            confidentialityConsent: application.data.confidentialityConsent === "I accept" ? 1 : 0,
            recordingconsent: application.data.recordingConsent === "I accept" ? 1 : 0,
            personalmeetings: 1
        };

        if (selectedApplicants.some(applicant => applicant.id === application.data.id)) {
            setSelectedApplicants(selectedApplicants.filter(applicant => applicant.id !== application.data.id));
        } else {
            setSelectedApplicants([...selectedApplicants, user]);
        }
    }

    const handleTransfer = async () => {
        for (const applicant of selectedApplicants) {
            try {
                await dispatch(createMember(applicant)).unwrap();
                setSelectedApplicants([]);
                toast.success("Applicants transferred successfully");
            } catch (error) {
                console.error("Failed to create member:", error);
                toast.error(`Failed to transfer applicant ${applicant.name} ${applicant.lastname}`);
            } finally {
                await dispatch(getMembers(selectedGroup.id)).unwrap();
                setOpenModal(false);
            }
        }
    }

    const confirmTransferModal = () => {
        console.log(selectedApplicants);
        return (
            <Modal size="large" open={openModal} onClose={() => setOpenModal(false)}>
                <Modal.Header>Confirmation Required</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Header as="h3">Please confirm the following applicants to be transferred to {selectedGroup.groupname}</Header>
                        <Message info>
                            <Message.Header>The user will be created with the following default values:</Message.Header>
                            <Message.List>
                                <Message.Item>Role: Participant</Message.Item>
                                <Message.Item>Username: name.lastname</Message.Item>
                                <Message.Item>Default Password</Message.Item>
                            </Message.List>
                        </Message>
                        <Table celled stackable compact padded>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>ID</Table.HeaderCell>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>Lastname</Table.HeaderCell>
                                    <Table.HeaderCell>Username</Table.HeaderCell>
                                    <Table.HeaderCell>Email</Table.HeaderCell>
                                    <Table.HeaderCell>Role</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    selectedApplicants.map((user) => (
                                        <Table.Row key={user.id}>
                                            <Table.Cell>{user.id.slice(0, 8)}</Table.Cell>
                                            <Table.Cell>{user.name}</Table.Cell>
                                            <Table.Cell>{user.lastname}</Table.Cell>
                                            <Table.Cell>{user.username}</Table.Cell>
                                            <Table.Cell>{user.email}</Table.Cell>
                                            <Table.Cell>{user.role.charAt(0).toUpperCase() + user.role.slice(1)}</Table.Cell>
                                        </Table.Row>
                                    ))
                                }
                            </Table.Body>
                        </Table>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button size="large" onClick={() => setOpenModal(false)}>Cancel</Button>
                    <Button loading={isLoadingMem} size="large" primary onClick={handleTransfer}>Transfer</Button>
                </Modal.Actions>
            </Modal>
        )
    }

    return (
        <>
            <Container style={{ marginTop: "2em" }}>
                <Header as="h1" textAlign="center">Transfer Applicants as Users
                    <Header.Subheader>This will create users for the selected applicants and transfer them to the selected group</Header.Subheader>
                </Header>
                <Segment>
                    <Menu size="huge" pagination stackable widths={2}>
                        <Dropdown item text={ selectedGroup ? selectedGroup.groupname : "Select Group" } value={selectedGroup} loading={isLoadingGrp} disabled={isLoadingGrp} scrolling>
                            <Dropdown.Menu>
                               {
                                    groupsArr.map((group) => (
                                    <Dropdown.Item key={group.id} onClick={() => selectGroup(group)}>{group.groupname}</Dropdown.Item>
                                    ))
                               }
                            </Dropdown.Menu>
                        </Dropdown>
                        <Menu.Item onClick={() => setOpenModal(true)} disabled={selectedGroup === "" || selectedApplicants.length === 0}>Transfer Selected Users</Menu.Item>
                        
                    </Menu>
                </Segment>
            </Container>
            <Grid columns={2} stackable style={{ margin: "1em" }}>
                <Grid.Column>
                    <Segment loading={isLoadingSrv}>
                        <Table textAlign="center" structured celled stackable compact padded selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell  colSpan={4} >Applicants</Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell>ID</Table.HeaderCell>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>Lastname</Table.HeaderCell>
                                    <Table.HeaderCell>Date Applied</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    filteredApplications.sort((a, b) => new Date(b.data.datetimeApplied) - new Date(a.data.datetimeApplied)).map((application) => (
                                        <Table.Row 
                                            key={application.data.id}
                                            active={selectedApplicants.some(applicant => applicant.id === application.data.id)}
                                            onClick={() => toggleApplicantSelection(application)}
                                            disabled={selectedGroup === ""}
                                        >
                                            <Table.Cell>{application.data.id.slice(0, 8)}</Table.Cell>
                                            <Table.Cell>{application.data.name}</Table.Cell>
                                            <Table.Cell>{application.data.lastname}</Table.Cell>
                                            <Table.Cell>{DateTime.fromRFC2822(application.data.datetimeApplied).setLocale('en-CA').toFormat('cccc, FFF')}</Table.Cell>
                                        </Table.Row>
                                    ))
                                }
                            </Table.Body>
                        </Table>
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Segment loading={isLoadingMem}>
                        <Table textAlign="center" celled stackable compact padded>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>User ID</Table.HeaderCell>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>Lastname</Table.HeaderCell>
                                    <Table.HeaderCell>Username</Table.HeaderCell>
                                    <Table.HeaderCell>Role</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {membersArr?.map((member) => (
                                    <Table.Row key={member?.id}>
                                        <Table.Cell>{member?.id?.slice(0, 8)}</Table.Cell>
                                        <Table.Cell>{member?.name}</Table.Cell>
                                        <Table.Cell>{member?.lastname}</Table.Cell>
                                        <Table.Cell>{member?.username}</Table.Cell>
                                        <Table.Cell>{member?.role?.charAt(0).toUpperCase() + member?.role?.slice(1)}</Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </Segment>
                </Grid.Column>
            </Grid>
            {confirmTransferModal()}
        </>
    )
}

export default Transfer;