import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Divider, Form, Header, Segment } from "semantic-ui-react";
import { v4 as uuidv4 } from "uuid";
import DragDrop from "../components/DragDrop";
import { deleteGroup, getAllGroups } from "../features/groups/groupSlice";
import {
  createMember,
  getAll,
  getMembers,
} from "../features/members/memberSlice";
import { uploadImageToBucket } from "../features/upload/uploadSlice";

const CreateMember = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { groupID } = useParams();

  const { user } = useSelector((state) => state.auth);
  const { allMembers, isLoading } = useSelector((state) => state.members);
  const { groupsArr } = useSelector((state) => state.groups);
  
  useEffect(() => {
    dispatch(getAll());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllGroups());
  }, [dispatch]);

  const member = allMembers.find((member) => member.groupid === groupID);

  const group = groupsArr.find((group) => group.id === groupID);

  const [loading, setLoading] = useState(false);
  //  this is the sate for the photo
  const [previewSource, setPreviewSource] = useState(null);

  const [memberData, setMemberData] = useState({
    id: uuidv4(),
    username: "",
    role: member && member.role ? member.role : "",
    password: "FL2F-participant",
    confirm: "FL2F-participant",
    name: "",
    lastname: "",
    groupid: groupID ? groupID : "",
    email: "",
    instemail: "",
    phonenumber: "",
    linkedin: "",
    edulevel: "",
    facilitator: group && group.facilitator ? group.facilitator : "",
    image: 0,
  });

  const {
    id,
    username,
    password,
    confirm,
    name,
    lastname,
    groupid,
    role,
    edulevel,
    email,
    instemail,
    phonenumber,
    linkedin,
    facilitator,
    image,
  } = memberData;

  const onChange = (e) => {
    setMemberData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const passCheck = () => {
    if (password !== confirm) return false;
    return true;
  };

  const passStrength = (password) => {
    const regex = /^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$/;
    return regex.test(password);
  };

  const uploadImage = async (base64EncodedImage) => {
    const splitImage = base64EncodedImage.split(",")[1];
    setLoading(true);
    try {
      await dispatch(
        uploadImageToBucket({
          id: id,
          groupid: groupID,
          name: name,
          lastname: lastname,
          image: splitImage,
        })
      ).unwrap();
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Error uploading image");
    }
  };

  const validateEmail = (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(email)) {
      return true;
    }
    return false;
  };

  const checkLetters = (word) => {
    if (/^\s*([0-9a-zA-Z. _-]*)\s*$/.test(word)) {
      return true;
    }
    return false;
  };

  const validatePhoneNumber = (number) => {
    if (/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(number)) {
      return true;
    }
    return false;
  };


  //Dispatch actions get users from group after creating a new member
  // Submit schedule and events
  const onSubmit = async (e) => {
    e.preventDefault();
    let canSave = [];
    if (role === "guest") {
      canSave = [
        id,
        username,
        name,
        lastname,
        groupid,
        role,
        instemail,
        //  password, phonenumber, and linkedin are not required for guests
      ].every((el) => el.length >= 1);
    } else {
      canSave = [
        id,
        username,
        password,
        confirm,
        name,
        lastname,
        role,
        instemail,
        //  phonenumber, and linkedin are not required
      ].every((el) => el.length >= 1);
    }

    if (!canSave) {
      toast.error("Please fill in all the fields");
      console.log("id", id);
      console.log("username", username);
      console.log("password", password);
      console.log("name", name);
      console.log("lastname", lastname);
      console.log("groupid", groupid);
      console.log("role", role);
      return;
    }

    if (!checkLetters(username)) {
      return toast.error("Invalid characters in username");
    }

    if (username.includes(" ")) {
      return toast.error("Username must not contain spaces");
    }

    if (!checkLetters(name)) {
      return toast.error("Invalid characters in name");
    }

    if (!checkLetters(lastname)) {
      return toast.error("Invalid characters in last name");
    }

    if (!validateEmail(instemail)) {
      return toast.error("Please enter a valid email.");
    }

    if (phonenumber) {
      if (!validatePhoneNumber(phonenumber)) {
        return toast.error("Please enter a valid phone number.");
      }
    }

    const foundMember = allMembers.some(
      (member) => member.username === username
    );

    if (foundMember) {
      toast.error("Username already exists. Please change username");
      return;
    }

    if (!passCheck()) {
      toast.error("passwords do no match");
      return;
    }

    if (!passStrength(password)) {
      toast.error(
        "password must contain at least 1 letter, number, and be at least 8 characters long"
      );
      return;
    }

    try {
      if (previewSource) {
        await uploadImage(previewSource);
      }
      if (role !== "guest") {
        dispatch(
          createMember({
            id,
            username,
            password,
            groupid,
            name,
            lastname,
            role,
            edulevel,
            email,
            instemail,
            phonenumber,
            linkedin,
            facilitator,
            image,
          })
        );
      } else {
        dispatch(
          createMember({
            id,
            username,
            name,
            lastname,
            groupid,
            role,
            edulevel,
            email,
            instemail,
            phonenumber,
            linkedin,
            image,
          })
        );
      }

      toast.success("Member successfully created");

      dispatch(getMembers(groupid));
      dispatch(getAllGroups());
      dispatch(getAll());
      navigate(`/${groupid}`);
    } catch (error) {
      const message =
        error.response.data.message || error.message || error.toString();
      toast.error(message);
      console.log(message);
    }
  };

  const onCancel = (e) => {
    e.preventDefault();
    const groupWithMembers = allMembers.some(
      (member) => member.groupid === groupID
    );

    if (groupWithMembers) {
      navigate(`/${groupID}`);
    } else {
      try {
        navigate("/");
      } catch (error) {
        const message =
          error.response.data.message || error.message || error.toString();
        toast.error(message);
        console.log(message);
        navigate("/");
      }
    }
  };

  const roleoptions = [
    { key: "guest", text: "Guest", value: "guest" },
    { key: "participant", text: "Participant", value: "participant" },
    { key: "alumni", text: "Alumni", value: "alumni" },
    { key: "facilitator", text: "Facilitator", value: "facilitator" },
    
  ];

  if ( user.role === "admin") {
    roleoptions.push({ key: "admin", text: "Admin", value: "admin" });
  }

  return (
    <Container>
      <Divider hidden />
      <Header as="h1" textAlign="center"> Create New Member </Header>
      <Segment raised loading={isLoading || loading} size="massive">
        <Form size="huge" onSubmit={onSubmit}>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              label="Name"
              placeholder="Name"
              name="name"
              value={name}
              onChange={onChange}
              required
            />
            <Form.Input
              fluid
              label="Last Name"
              placeholder="Last Name"
              name="lastname"
              value={lastname}
              onChange={onChange}
              required
            />
            <Form.Input
              fluid
              label="Username"
              placeholder="Username"
              name="username"
              value={username}
              onChange={onChange}
              required
            />
          </Form.Group>
          {
            user.role === "admin" && role !== "guest" && (
              <Form.Group widths="equal">
                <Form.Input
                  fluid
                  label="Password"
                  placeholder="Password"
                  type="password"
                  name="password"
                  value={password}
                  onChange={onChange}
                  required
                />
                <Form.Input
                  fluid
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  type="password"
                  name="confirm"
                  value={confirm}
                  onChange={onChange}
                  required
                />
              </Form.Group>
            )
          }
          <Form.Group widths="equal">
            <Form.Dropdown
              fluid
              label="Role"
              selection
              options={roleoptions}
              name="role"
              value={role}
              onChange={(e, { value }) => setMemberData((prevState) => ({ ...prevState, role: value }))}
              required
            />
            <Form.Input
              fluid
              label="Group Name"
              placeholder="Enter a Group Name"
              name="groupid"
              value={groupid}
              onChange={onChange}
              readOnly
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              label="Email"
              placeholder="Email"
              type="email"
              name="email"
              value={email}
              onChange={onChange}
            />
            <Form.Input
              fluid
              label="Institutional Email"
              placeholder="Institutional Email"
              type="email"
              name="instemail"
              value={instemail}
              onChange={onChange}
              required
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              label="Phone Number"
              placeholder="xxx xxx xxxx"
              type="tel"
              name="phonenumber"
              value={phonenumber}
              onChange={onChange}
            />
            <Form.Input
              fluid
              label="LinkedIn"
              placeholder="LinkedIn"
              name="linkedin"
              value={linkedin}
              onChange={onChange}
            />
            <Form.Dropdown
              fluid
              label="Education Level"
              selection
              options={[
                { key: "Bsc", text: "Bachelor's Degree", value: "Bsc" },
                { key: "Msc", text: "Master's Degree", value: "Msc" },
                { key: "PhD", text: "PhD", value: "PhD" },
              ]}
              name="edulevel"
              value={edulevel}
              onChange={(e, { value }) => setMemberData((prevState) => ({ ...prevState, edulevel: value }))}
              required
            />
          </Form.Group>
          {/* {
            role === "participant" && (
              <Form.Input
                fluid
                label="Google Meet Link"
                placeholder="Google Meet Link"
                name="googleMeet"
                value={googleMeet}
                onChange={onChange}
                required
              />
            )
          } */}
          <Form.Field>
            <label>Profile Picture</label>
            <DragDrop previewSource={previewSource} setPreviewSource={setPreviewSource} setMemberData={setMemberData} />
          </Form.Field>
          <Divider />
          <Form.Group widths="equal">
            <Form.Button fluid size="big" color="green" type="submit">
              Save
            </Form.Button>
            <Form.Button fluid size="big" color="red" onClick={onCancel}>
              Cancel
            </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
    </Container>
  );
};
export default CreateMember;
