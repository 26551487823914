import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import uploadService from "./uploadService";

const initialState = {
  image: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Upload Image to Bucket
export const getImageFromBucket = createAsyncThunk(
  "image/get",
  async (imageData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await uploadService.getImageFromBucket(imageData, token);
    } catch (error) {
      const message = error.response.data.message || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Upload Image to Bucket
export const uploadImageToBucket = createAsyncThunk(
  "image/upload",
  async (imageData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await uploadService.uploadImage(imageData, token);
    } catch (error) {
      const message = error.response.data.message || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Upload Image to Bucket
export const deleteImageFromBucket = createAsyncThunk(
  "image/delete",
  async (imageData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await uploadService.deleteImageFromBucket(imageData, token);
    } catch (error) {
      const message = error.response.data.message || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    resetImage: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      //get image from bucket
      .addCase(getImageFromBucket.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getImageFromBucket.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.image = action.payload;
      })
      .addCase(getImageFromBucket.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      //upload image to bucket
      .addCase(uploadImageToBucket.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadImageToBucket.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(uploadImageToBucket.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      //delete image from bucket
      .addCase(deleteImageFromBucket.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteImageFromBucket.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(deleteImageFromBucket.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { resetImage } = uploadSlice.actions;

export default uploadSlice.reducer;
