import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Segment } from "semantic-ui-react";
import DragDrop from "../components/DragDrop";
import Spinner from "../components/Spinner";
import { updateImageURL } from "../features/members/memberSlice";
import { uploadImageToBucket } from "../features/upload/uploadSlice";

const UploadPhoto = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isError, message, isLoading } = useSelector(
    (state) => state.auth
  );

  const [loading, setLoading] = useState(false);
  const [previewSource, setPreviewSource] = useState(
    user && user.image
      ? `https://storage.googleapis.com/fl2f-microservice-bucket1/${user.name}_${user.lastname}_${user.id}_${user.groupid}.png`
      : null
  );

  const [memberData, setMemberData] = useState({
    image: user && user.image,
  });

  const { image } = memberData;

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
  }, [isError, message]);

  useEffect(() => {
    if (user && image) {
      setPreviewSource(
        `https://storage.googleapis.com/fl2f-microservice-bucket1/${user.name}_${user.lastname}_${user.id}_${user.groupid}.png`
      );
    }
  }, [user, image]);

  const uploadImage = async (base64EncodedImage) => {
    const splitImage = base64EncodedImage.split(",")[1];
    setLoading(true);
    try {
      await dispatch(
        uploadImageToBucket({
          id: user.id,
          name: user.name,
          lastname: user.lastname,
          image: splitImage,
        })
      );
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Error uploading image");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const canSave = image !== null;
    if (!canSave) {
      toast.error("Please upload image");
      return;
    }

    try {
      if (previewSource) {
        uploadImage(previewSource)
          .then(() => {
            dispatch(
              updateImageURL({
                id: user.id,
                image,
              })
            );
            toast.success("Photo successfully updated");
          })
          .catch((error) => {
            toast.error("Failed to upload photo");
          });
      } else {
        toast.error("Please upload a photo");
      }
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      toast.error(message);
      console.log(message);
    }
  };

  if (isLoading || loading) return <Spinner />;

  return (
    <Container>
      <Segment>
        <DragDrop
          previewSource={previewSource}
          setPreviewSource={setPreviewSource}
        />
        <button
          className="ui button primary"
          onClick={onSubmit}
          style={{ marginTop: "1em" }}
        >
          Upload
        </button>
      </Segment>
    </Container>
  );
};

export default UploadPhoto;
