import axios from "axios";

let API_URL = "";
if (process.env.NODE_ENV !== "production") {
  API_URL = "http://localhost:5000/api/upload/"; // lets us use the backend server in local development
}
else {
  API_URL = "/api/upload/";
}


const getImageFromBucket = async (imageData, token) => {
  const { fileName } = imageData;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await axios.get(API_URL + fileName, config);

  return data;
};

const uploadImage = async (imageData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-type": "application/json",
    },
  };
  const { data } = await axios.post(API_URL, imageData, config);

  return data;
};

const deleteImageFromBucket = async (imageData, token) => {
  const { id } = imageData;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: imageData,
  };
  const { data } = await axios.delete(API_URL + `${id}`, config);

  return data;
};

const uploadService = {
  uploadImage,
  deleteImageFromBucket,
  getImageFromBucket,
};

export default uploadService;
