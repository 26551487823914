import { useRef } from "react";
import { toast } from "react-toastify";
import { Button, Container, Header, Icon, Image, Segment } from "semantic-ui-react";
import "../styles/dragDrop.scss";

const DragDrop = ({ previewSource, setPreviewSource, setMemberData }) => {
  const inputRef = useRef();

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource(reader.result);
    };
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file.size > 5000000)
      return toast.error("Image size must not be greater that 5mb");
    previewFile(file);

    setMemberData((prevState) => ({
      ...prevState,
      image: 1,
    }));
  };

  const handleSelect = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file.size > 5000000)
      return toast.error("Image size must not be greater that 5mb");
    previewFile(file);
    setMemberData((prevState) => ({
      ...prevState,
      image: 1,
    }));
  };

  return (
    <Container style={{width: "500px"}}>
      {
        previewSource ? (
          <Segment>
            <Image bordered src={previewSource} size="medium" centered />
            <br />
            <Button fluid color="red" onClick={() => {setPreviewSource(null); setMemberData((prevState) => ({ ...prevState, image: 0, }));}}>Remove Image</Button>
          </Segment>
          
        ) : (
          <div onDragOver={handleDragOver} onDrop={handleDrop}>
            <Segment placeholder>
              <Header as="h2" icon>
                <Icon name="file image outline" />
                Drag and drop an image here
                <Header.Subheader>or select an image from your computer</Header.Subheader>
              </Header>
              <input
                type="file"
                ref={inputRef}
                style={{ display: "none" }}
                onChange={handleSelect}
                hidden
              />
              <Button type="button" size="big" onClick={() => inputRef.current.click()}>Select Image</Button>
            </Segment>
          </div>
        )
      
      }
    </Container>
  );
};
export default DragDrop;
