import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Container, Header, Popup, Segment } from "semantic-ui-react";
import { deleteGroup, getAllGroups, reset } from "../features/groups/groupSlice";
import {
  deleteMember,
  getAll,
  getMembers,
} from "../features/members/memberSlice";
import { deleteImageFromBucket } from "../features/upload/uploadSlice";

const Confirm = () => {
  const { allMembers, membersArr } = useSelector((state) => state.members);

  const { groupsArr } = useSelector((state) => state.groups);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, groupID } = useParams();

  useEffect(() => {
    if (!membersArr.length && !allMembers.length) {
      dispatch(getAll());
      dispatch(getMembers(groupID));
    }
  }, [dispatch, membersArr, allMembers, groupID]);

  useEffect(() => {
    dispatch(getAll());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMembers(groupID));
  }, [dispatch, groupID]);


  const currentMember = membersArr?.find((member) => member.id === id);

  const currentGroup = groupsArr?.find((group) => group.id === groupID);

  const fileName = currentMember ? `${currentMember.name}_${currentMember.lastname}_${id}_${groupID}.png`: null;

  const onCancel = () => {
    navigate(`/${groupID}`);
  };

  // the on delete is checking first if the delete is for the entire group, or for an individual member. If it is for an indivifual, it is checking to see if this is the only member in the group. If it is the only member, it will delete the member as well as the group. If there are more than 1 member in the group, it only deletes the individual member.
  const onDelete = () => {
    if (id) {
      try {
        if (fileName && currentMember.image) {
          dispatch(deleteImageFromBucket({
            id: id,
            groupid: groupID,
          }));
        }

        dispatch(deleteMember(id));
        toast.success("User Deleted");
        dispatch(reset());
        navigate(`/${groupID}`);
      } catch (error) {
        console.log(error);
        toast.error(error);
      }
    } else {
      try {
        dispatch(deleteGroup(groupID));
        toast.success("Group Deleted");
        dispatch(reset());
        navigate(`/`);
      } catch (error) {
        console.log(error);
        toast.error(error);
      }
    }
  };

  //implement modal from calendar generator later

  return (
    <div className="appContainer">
      <main className="body-container">
        <Container style={{ width: "500px"}}>
          <Segment size="massive">
            {
              id ? (
                <Header  textAlign="center"> Are you sure you want to delete this user? </Header>
              ) : (
                <Header textAlign="center"> Are you sure you want to delete {currentGroup.groupname}? </Header>
              )
            }
            <Segment basic textAlign="center">
              <Button.Group fluid>
                <Popup
                  content={<Button size="huge" onClick={onDelete}>This action cannot be undone!</Button>}
                  trigger={<Button size="huge" color="red" content="Delete" />}
                  on="click"
                  position="top center"
                  flowing
                />
                <Button size="huge" color="green" onClick={onCancel}>Cancel</Button>
              </Button.Group>
            </Segment>
          </Segment>
        </Container>
      </main>
    </div>
  );
};
export default Confirm;
