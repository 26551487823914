import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Container, Header, Segment } from "semantic-ui-react";

const ErrorPage = () => {

  const { user } = useSelector((state) => state.auth);

  return (
    <Container style={{ margin: '7em' }}>
      <Segment >
        <Header as="h1" textAlign="center"> Error 404 </Header>
        <Header as="h3" textAlign="center"> Page Not Found </Header>
        {
          user && user.role === "participant" ? (
            <Button as={Link} to="/uploadPhoto" primary fluid size="huge">
              Go to back
            </Button>
          ) : (
            <Button as={Link} to="/" primary fluid size="huge">
              Go to Home
            </Button>
          )
        }
        
      </Segment>
    </Container>
  );
};
export default ErrorPage;
