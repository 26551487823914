import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Dropdown, Header, Loader, Modal, Segment, Table } from "semantic-ui-react";
import { addGroupFacilitator, getAllGroups } from "../features/groups/groupSlice";
import {
  getAll,
  getMembers,
  resetPass,
  updateFacilitator,
  updateRole,
} from "../features/members/memberSlice";
import { resetImage } from "../features/upload/uploadSlice";

const DashboardForGroup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { groupsArr, isLoading: isLoadingGrp } = useSelector((state) => state.groups);
  const { allMembers, membersArr, pass, isLoading: isLoadingMbr, isError, message } = useSelector(
    (state) => state.members
  );

  // the selected group's id
  const { groupID } = useParams();

  useEffect(() => {
    if (pass.length >= 1) {
      dispatch(resetPass());
    }
  }, [dispatch, pass]);

  useEffect(() => {
    dispatch(getAllGroups());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAll());
  }, [dispatch]);

  const group = groupsArr?.find((group) => group.id === groupID);

  useEffect(() => {
    dispatch(getMembers(groupID));
    dispatch(resetImage());
  }, [dispatch, groupID, group]);
  
  // this creates an array of admin and facilitator users
  const admins = allMembers?.filter(
    (member) => member.role === "admin" || member.role === "facilitator"
  );

  const members = membersArr.filter((member) => member.groupid === groupID);

  const [facilitatorsModalOpen, setFacilitatorsModalOpen] = useState(false);
  const [googleMeetModalOpen, setGoogleMeetModalOpen] = useState(false);
  const [groupRolesModalOpen, setGroupRolesModalOpen] = useState(false);
  const [selectedFacilitators, setSelectedFacilitators] = useState([]);
  const [googleMeetLink, setGoogleMeetLink] = useState(members[0] && members[0].googleMeet ? members[0].googleMeet : "");
  const [selectedRole, setSelectedRole] = useState("");

  const facilitatorOptions = admins.map((admin) => ({
    key: admin.id,
    text: `${admin.name} ${admin.lastname} (ID: ${admin.id})`, 
    value: admin.id,
  }));

  const roleOptions = [
    { key: 'guest', text: 'Guest', value: 'guest' },
    { key: 'participant', text: 'Participant', value: 'participant' },
    { key: 'alumni', text: 'Alumni', value: 'alumni' },
    { key: 'facilitator', text: 'Facilitator', value: 'facilitator' },
    { key: 'admin', text: 'Admin', value: 'admin' },
  ];

  const onDelete = () => {
    navigate(`/${groupID}/confirm`);
  };

  const handleFacilitatorSubmit = async () => {
    const facilitatorIds = selectedFacilitators.join(", ");
    try {
      await dispatch(addGroupFacilitator({ groupID, facilitator: facilitatorIds })).unwrap();
      await dispatch(updateFacilitator({ groupID, facilitator: facilitatorIds })).unwrap();
      await dispatch(getAll()).unwrap();
      await dispatch(getMembers(groupID)).unwrap();

      
      toast.success("Facilitators Updated for Group");
    } catch (error) {
      const message =
        error.response.data.message || error.message || error.toString();
      toast.error(message);
    } finally {
      setFacilitatorsModalOpen(false);
      setSelectedFacilitators(members && members[0] ? members[0].facilitator.split(", ") : []);
    }
  };

  // const handleGoogleMeetSubmit = () => {
  //   if (!googleMeetLink) return toast.error("Please paste meeting link");
  //   try {
  //     dispatch(updateGoogleMeet({ groupID, googleMeet: googleMeetLink }));
  //     dispatch(getAll());
  //     dispatch(getMembers(groupID));
  //     setGoogleMeetModalOpen(false);
  //     toast.success("Google Meet Updated for Group");
  //   } catch (error) {
  //     const message =
  //       error.response.data.message || error.message || error.toString();
  //     toast.error(message);
  //   }
  // };

  const handleRoleSubmit = async () => {
    if (!selectedRole) return toast.error("Please select a role");
    try {
      await dispatch(updateRole({ groupID, role: selectedRole })).unwrap();
      await dispatch(getAll()).unwrap();
      setGroupRolesModalOpen(false);
      toast.success("Role Updated for Group");
    } catch (error) {
      const message =
        error.response.data.message || error.message || error.toString();
      toast.error(message);
    }
  };

  const groupDeletionStatus = () => {
    const groupStatus = membersArr.map((member) => member.preworkshop);

    if (groupStatus.includes(1)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="appContainer" >
      <main className="body-container">
        {user ? (
          <Segment loading={isLoadingMbr || isLoadingGrp} size="huge">
            <Header dividing as="h2" textAlign="center">User Management for {group?.groupname}
              {
                groupDeletionStatus() ? (
                  <Header.Subheader> At least one member of the group has survey information, deletion not allowed.</Header.Subheader>
                ) : null
              }
            </Header>
            <Table collapsing size="large" singleLine padded compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>User ID</Table.HeaderCell>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Username</Table.HeaderCell>
                  <Table.HeaderCell>Role</Table.HeaderCell>
                  <Table.HeaderCell>
                    <Button.Group>
                      <Button color="green" size="large" onClick={() => navigate(`/${groupID}/create-member`)}>Add Member</Button>
                      <Button color="black" size="large" onClick={() => navigate(`/`)}>Back</Button>
                    </Button.Group>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {members.map((member) => (
                  <Table.Row key={member.id}>
                    <Table.Cell>{member.id.slice(0, 8)}</Table.Cell>
                    <Table.Cell>{member.name}</Table.Cell>
                    <Table.Cell>{member.lastname}</Table.Cell>
                    <Table.Cell>{member.role.charAt(0).toUpperCase() + member.role.slice(1)}</Table.Cell>
                    <Table.Cell >
                      <Button.Group compact fluid >
                        <Button size="large" onClick={() => navigate(`/${groupID}/member-profile/${member.id}`)}>Edit User</Button>
                        <Button size="large" disabled={member.preworkshop} onClick={() => navigate(`/${groupID}/confirm/${member.id}`)}>Delete</Button>
                      </Button.Group>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
              <Table.Footer fullWidth>
                <Table.Row >
                  <Table.HeaderCell colSpan={5}>
                    <Button.Group fluid floated="right">
                      <Button size="large" color="blue" onClick={() => {setFacilitatorsModalOpen(true); setSelectedFacilitators(group && group.facilitator ? group.facilitator.split(", ") : [])}} >Facilitators</Button>
                      {/* <Button size="large" color="teal" onClick={() => setGoogleMeetModalOpen(true)}  >Google Meet Link</Button> */}
                      <Button size="large" color="green" onClick={() => setGroupRolesModalOpen(true)} >Group Roles</Button>
                      <Button size="large" disabled={groupDeletionStatus()} negative onClick={onDelete}>Delete Group</Button>
                    </Button.Group>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>

            {/* Facilitators Modal */}
            <Modal
              open={facilitatorsModalOpen}
              onClose={() => {
                setFacilitatorsModalOpen(false);
                setSelectedFacilitators(group && group.facilitator ? group.facilitator.split(", ") : []);
              }}
              size="large"
            >
              <Header content="Select Facilitators" />
              <Modal.Content>
                <Dropdown
                  placeholder="Select Facilitators"
                  fluid
                  multiple
                  search
                  selection
                  options={facilitatorOptions}
                  value={selectedFacilitators}
                  onChange={(e, { value }) => setSelectedFacilitators(value)}
                />
              </Modal.Content>
              <Modal.Actions>
                <Button color="red" onClick={() => {setFacilitatorsModalOpen(false); setSelectedFacilitators(group && group.facilitator ? group.facilitator.split(", ") : [])}}>
                  Cancel
                </Button>
                <Button color="green" onClick={handleFacilitatorSubmit}>
                  Save
                </Button>
              </Modal.Actions>
            </Modal>

            {/* Google Meet Link Modal */}
            <Modal
              open={googleMeetModalOpen}
              onClose={() => setGoogleMeetModalOpen(false)}
              size="small"
            >
              <Header content="Google Meet Link" />
              <Modal.Content>
                <div className="form-group">
                  <label htmlFor="googleMeet">Google Meet Link *</label>
                  <input
                    type="url"
                    name="googleMeet"
                    id="googleMeet"
                    placeholder="Paste in Google Meet link"
                    value={googleMeetLink}
                    onChange={(e) => setGoogleMeetLink(e.target.value)}
                  />
                  <a
                    href="https://meet.google.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                  >
                    Create link here
                  </a>
                </div>
              </Modal.Content>
              <Modal.Actions>
                <Button color="red" onClick={() => setGoogleMeetModalOpen(false)}>
                  Cancel
                </Button>
                <Button color="green">
                  Save
                </Button>
              </Modal.Actions>
            </Modal>

            {/* Group Roles Modal */}
            <Modal
              open={groupRolesModalOpen}
              onClose={() => {setGroupRolesModalOpen(false); setSelectedRole("")}}
              size="small"
            >
              <Header content="Select Role" />
              <Modal.Content>
                <Dropdown
                  placeholder="Select a Role"
                  fluid
                  selection
                  options={roleOptions}
                  value={selectedRole}
                  onChange={(e, { value }) => setSelectedRole(value)}
                />
              </Modal.Content>
              <Modal.Actions>
                <Button color="red" onClick={() => {setGroupRolesModalOpen(false); setSelectedRole("")}}>
                  Cancel
                </Button>
                <Button color="green" onClick={handleRoleSubmit}>
                  Save
                </Button>
              </Modal.Actions>
            </Modal>
          </Segment>
        ) : (
          <>
            <h2>You're not logged in</h2>
            <Link to="/login">
              <button className="btn btn-primary btn-block" type="submit">
                Please Login
              </button>
            </Link>
          </>
        )}
      </main>
    </div>
  );
};
export default DashboardForGroup;

