import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Divider, Form, Header, Segment } from "semantic-ui-react";
import { v4 as uuidv4 } from "uuid";
import { createGroup, reset } from "../features/groups/groupSlice";

const CreateGroup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const { groupsArr, isLoading, isError, message } = useSelector(
    (state) => state.groups
  );

  useEffect(() => {
    if (isError)
    toast.error(
      message === "Request failed with status code 409"
        ? "Create Failed. Username already exists."
        : message
    );
    
  }, [user, navigate, isError, message]);

  const generateAppCode = () => {
    return Math.floor(1000 + Math.random() * 9000).toString();
  };

  const [groupData, setGroupData] = useState({
    groupID: uuidv4(),
    groupname: "",
    grouprole: "",
    logo1: "",
    bottomText: "",
    appcode: generateAppCode(),
  });

  const { groupID, groupname, grouprole, logo1, bottomText, appcode } = groupData;

  const groupExists = groupsArr.some((group) => group.groupid === groupID);

  const checkLetters = (word) => {
    if (/^\s*([0-9a-zA-Z _-]*)\s*$/.test(word)) {
      return true;
    }
    return false;
  };

  // Submit schedule and events
  const onSubmit = (e) => {
    e.preventDefault();
    if (groupExists) return toast.error("Group already exists");

    if (groupname.length === 0) {
      toast.error("Please add a group title");
      return;
    }

    if (!checkLetters(groupname)) {
      return toast.error("Invalid characters in Group Name.");
    }

    new Promise((resolve, reject) => {
      dispatch(
        createGroup({
          groupID,
          groupname,
          grouprole,
          logo1,
          bottomText,
          startdate: new Date().toISOString().split("T")[0],
          appcode,
        })
      )
        .then(() => {
          toast.success("Group successfully created.");
          dispatch(reset());
          navigate("/");
          resolve();
        })
        .catch((error) => {
          const message =
            error.response.data.message || error.message || error.toString();
          toast.error(message);
          console.log(message);
          reject(error);
        });
    });
  };

  const logoOptions = [
    { key: "uofc", text: "University of Calgary", value: "https://storage.googleapis.com/staticphotos/UofCLogo.png" },
    { key: "uvic", text: "University of Victoria", value: "https://storage.googleapis.com/staticphotos/university_of_victoria.jpeg" },
    { key: "utoronto", text: "University of Toronto", value: "https://storage.googleapis.com/staticphotos/University_of_Toronto-Logo.wine.png" },
    { key: "queens", text: "Queens University", value: "https://storage.googleapis.com/staticphotos/queens_logo.png" },
    { key: "mcgill", text: "Mcgill University", value: "https://storage.googleapis.com/staticphotos/McGill_University_logo.png" },
    { key: "erin", text: "ERIN", value: "https://storage.googleapis.com/staticphotos/ERIN_logo.png" },
    { key: "we-bc", text: "WE-BC", value: "https://storage.cloud.google.com/staticphotos/WeBC-Iconmark-navy-200px.webp?" },
  ];

  const roleoptions = [
    { key: "guest", text: "Guest", value: "guest" },
    { key: "participant", text: "Participant", value: "participant" },
    { key: "alumni", text: "Alumni", value: "alumni" },
    { key: "facilitator", text: "Facilitator", value: "facilitator" },
    
  ];

  return (
    <>
      <Container style={{ width: "50%"}}>
        <Divider hidden />
        <Header as="h1" textAlign="center"> Create Group </Header>
        <Segment loading={isLoading} size="massive">
          <Form size="huge" onSubmit={onSubmit}>
            <Form.Input
              label="Group ID"
              placeholder="Group ID"
              type="text"
              name="groupID"
              value={groupID}
              readOnly
            />
            <Form.Input
              label="Group Name"
              placeholder="Group Name"
              type="text"
              name="groupID"
              value={groupname}
              onChange={(e) => setGroupData((prevState) => ({ ...prevState, groupname: e.target.value }))}
              required
            />
            <Form.Dropdown
              fluid
              label="Role"
              selection
              options={roleoptions}
              name="role"
              value={grouprole}
              onChange={(e, { value }) => setGroupData((prevState) => ({ ...prevState, grouprole: value }))}
              required
            />
            <Form.Dropdown
              label="Select affiliated University or Group Logo"
              placeholder="Select Logo"
              fluid
              selection
              clearable
              options={logoOptions}
              name="logo1"
              value={logo1}
              onChange={(e, { value }) => setGroupData((prevState) => ({ ...prevState, logo1: value }))}
            />
            {/* <Form.Input
              label="Bottom Text"
              type="text"
              name="bottomText"
              value={bottomText}
              onChange={onChange}
            /> */}
            <Form.Group widths="equal">
              <Form.Button fluid size="huge" color="green" content="Create" />
              <Form.Button fluid size="huge" color="red" onClick={() => navigate("/")}>Cancel</Form.Button>
            </Form.Group>
          </Form>
        </Segment>
      </Container>
    </>
  );
};
export default CreateGroup;
