import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/FL2F-logo.png";
import { logout, reset } from "../features/auth/authSlice";
import "../styles/header.scss";

import { Image, Menu } from "semantic-ui-react";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);

  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate("/login");
  };

  if (location.pathname === "/login") return null;

  return (
    <Menu size="huge">
      {user && user.role !== "participant" ? (
        <Menu.Item as={Link} to="/">
          <Image src={logo} size="small" />
        </Menu.Item>
      ) : (
        <Menu.Item>
          <Image src={logo} size="small" />
        </Menu.Item>
      )}
      <Menu.Menu position="right">
        <Menu.Item name="Logout" onClick={onLogout}>
          Logout
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
};

export default Navbar;
