import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logout } from "../features/auth/authSlice";
import { getAllGroups, reset } from "../features/groups/groupSlice";
import { getAll as getAllMembers, resetPass } from "../features/members/memberSlice";
import { resetImage } from "../features/upload/uploadSlice";
import "../styles/groupSelect.scss";

import { Button, Container, Header, Search, Segment, Table } from "semantic-ui-react";

const GroupSelect = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isLoading } = useSelector(
    (state) => state.auth
  );
  const { groupsArr } = useSelector((state) => state.groups);
  const { allMembers, pass, isLoading: isLoadingMem } = useSelector((state) => state.members);
  const [userSelect, setUserSelect] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const [sortColumn, setSortColumn] = useState('startdate');
  const [sortDirection, setSortDirection] = useState('descending');

  useEffect(() => {
    if (user.role === "participant") {
      navigate("/uploadPhoto");
    }
  }, [user, navigate]);

  useEffect(() => {
    if (pass !== "") {
      dispatch(resetPass());
    }
  }, [pass, dispatch]);

  useEffect(() => {
    if(user?.image) {
      dispatch(resetImage());
    }
  }, [user, dispatch]);

  useEffect(() => {
    dispatch(getAllGroups());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllMembers());
  }, [dispatch]);

  useEffect(() => {
    const searchQuery = userSelect.toLowerCase();
    const filtered = userSelect
      ? allMembers.filter((member) => {
          const name = member.name || member.username; // Assuming username is always present
          const lastName = member.lastname || '';
          return (
            name.toLowerCase().includes(searchQuery) ||
            lastName.toLowerCase().includes(searchQuery)
          );
        })
      : [];
    setSearchResults(filtered);
  }, [userSelect, allMembers]);

  const handleResultSelect = (e, { result }) => {
    navigate(`/${result.groupid}/member-profile/${result.id}`);
  };

  const resultRenderer = ({ id, name, lastname, role}) => (
    <div key={id}>
      <label style={{ fontSize: "16px" }} >{name} {lastname}</label>
      <p style={{ fontSize: "16px" }} >Role: {role.charAt(0).toUpperCase() + role.slice(1)}</p>
    </div>
  );

  const handleSort = (column) => {
    const newSortDirection = sortColumn === column && sortDirection === 'ascending' ? 'descending' : 'ascending';
    setSortColumn(column);
    setSortDirection(newSortDirection);
  };

  const groups = groupsArr.slice().sort((a, b) => {
    if (!sortColumn) return 0;
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];
    if (aValue < bValue) return sortDirection === 'ascending' ? -1 : 1;
    if (aValue > bValue) return sortDirection === 'ascending' ? 1 : -1;
    return 0;
  });

  return (
    <div className="appContainer">
      <main className="body-container">
        {user ? (
          <>
            <Header as="h1" textAlign="center"> User Management Panel
              <Header.Subheader>
                Please select or create a new group
              </Header.Subheader>
            </Header>
            <Segment loading={isLoading} compact secondary size="huge">
              {groupsArr.length > 0 ? (
                <Table sortable compact padded collapsing size="large" structured>
                  <Table.Header>
                    <Table.Row textAlign="center">
                      <Table.HeaderCell sorted={sortColumn === 'groupname' ? sortDirection : null} onClick={() => handleSort('groupname')} rowSpan='2'>Group Name</Table.HeaderCell>
                      
                      <Table.HeaderCell>
                        <Button.Group fluid size="huge">
                          <Button color="green" onClick={() => navigate(`/create-group`)}>New Group</Button>
                          <Button color="orange" onClick={() => navigate(`/transfer`)}>Transfer</Button>
                        </Button.Group>
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        <Container style={{ width: "250px"}}>
                          <Search 
                            placeholder="Search User..."
                            noResultsMessage="No users found"
                            icon="user"
                            results={searchResults} 
                            value={userSelect}
                            onSearchChange={(e) => setUserSelect(e.target.value)} 
                            onResultSelect={handleResultSelect}
                            resultRenderer={resultRenderer}
                            loading={isLoadingMem}
                            size="small" 
                          />
                        </Container>
                      </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row textAlign="center">
                      <Table.HeaderCell sorted={sortColumn === 'startdate' ? sortDirection : null} onClick={() => handleSort('startdate')} rowSpan='1'>Group Start Date</Table.HeaderCell>
                      <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {groups.map((group, index) => {
                      return (
                        <Table.Row textAlign='center' key={index}>
                          <Table.Cell>{group.groupname.charAt(0).toUpperCase() + group.groupname.slice(1)}</Table.Cell>
                          <Table.Cell>{DateTime.fromISO(group.startdate).toFormat('DD')}</Table.Cell>
                          <Table.Cell>
                            <Button color="blue" size="huge" fluid onClick={() => {dispatch(reset()); navigate(`/${group.id}`);}}>Edit Group</Button>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              ) : (
                <h3>No groups to show</h3>
              )}
            </Segment>
          </>
        ) : (
          <>
            <h2>You're not logged in</h2>
            <Link to="/login">
              <button className="btn btn-primary btn-block" type="submit">
                Please Login
              </button>
            </Link>
          </>
        )}
      </main>
    </div>
  );
};
export default GroupSelect;
