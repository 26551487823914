import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Confirm from "./components/Confirm";
import Navbar from "./components/Navbar";
import PrivateRoutes from "./features/utils/PrivateRoutes";
import CreateGroup from "./pages/CreateGroup";
import CreateMember from "./pages/CreateMember";
import DashboardForGroup from "./pages/DashboardForGroup";
import ErrorPage from "./pages/ErrorPage";
import GroupSelect from "./pages/GroupSelect";
import Login from "./pages/Login";
import MemberEdit from "./pages/MemberEdit";
import Transfer from "./pages/Transfer";
import UploadPhoto from "./pages/UploadPhoto";

function App() {

  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoutes />}> {/* Wrap protected routes inside PrivateRoutes */}
            <Route path="/" element={<GroupSelect />} />
            <Route path="/transfer" element={<Transfer />} />
            <Route path="/uploadPhoto" element={<UploadPhoto />} />
            <Route path="/:groupID" element={<DashboardForGroup />} />
            <Route path="/create-group" element={<CreateGroup />} />
            <Route path="/create-member" element={<CreateMember />} />
            <Route path="/:groupID/create-member" element={<CreateMember />} />
            <Route path="/:groupID/member-profile/:id" element={<MemberEdit />} />
            <Route path="/:groupID/confirm/" element={<Confirm />} />
            <Route path="/:groupID/confirm/:id/" element={<Confirm />} />
          </Route>
          <Route path="/error" element={<ErrorPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
