import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Container, Divider, Form, Header, Popup, Segment } from "semantic-ui-react";
import DragDrop from "../components/DragDrop";
import {
  editMember,
  getAll,
  getMembers,
  getPass,
  resetPass,
  updatePass,
} from "../features/members/memberSlice";
import {
  deleteImageFromBucket,
  resetImage,
  uploadImageToBucket,
} from "../features/upload/uploadSlice";

const MemberEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, groupID } = useParams();
  const { user } = useSelector((state) => state.auth);
  const { allMembers, membersArr, pass, isLoading } = useSelector((state) => state.members);

  useEffect(() => {
    dispatch(getAll());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMembers(groupID));
  }, [dispatch, groupID]);

  useEffect(() => {
    dispatch(getPass(id));
  }, [dispatch, id]);

  let member = membersArr.find((member) => String(member.id) === id);

  const initialUsername = member ? member.username : null;

  const [memberData, setMemberData] = useState({
    id: member && member.id ? member.id : id,
    username: member && member.username ? member.username : "",
    name: member && member.name ? member.name : "",
    lastname: member && member.lastname ? member.lastname : "",
    groupid: member && member.groupid ? member.groupid : groupID,
    role: member && member.role ? member.role : "",
    edulevel: member && member.edulevel ? member.edulevel : "",
    email: member && member.email ? member.email : "",
    instemail: member && member.instemail ? member.instemail : "",
    phonenumber: member && member.phonenumber ? member.phonenumber : "",
    linkedin: member && member.linkedin ? member.linkedin : "",
    facilitator: member && member.facilitator ? member.facilitator : "",
    image: member && member.image ? member.image : 0,
  });

  const [previewSource, setPreviewSource] = useState(null);

  useEffect(() => {
    setPreviewSource(
      member && member.image
        ? `https://storage.googleapis.com/fl2f-microservice-bucket1/${member.name}_${member.lastname}_${id}_${groupID}.png`
        : 0
    );

    setMemberData({
      id: member && member.id ? member.id : id,
      username: member && member.username ? member.username : "",
      name: member && member.name ? member.name : "",
      lastname: member && member.lastname ? member.lastname : "",
      groupid: member && member.groupid ? member.groupid : groupID,
      role: member && member.role ? member.role : "",
      edulevel: member && member.edulevel ? member.edulevel : "",
      email: member && member.email ? member.email : "",
      instemail: member && member.instemail ? member.instemail : "",
      phonenumber: member && member.phonenumber ? member.phonenumber : "",
      linkedin: member && member.linkedin ? member.linkedin : "",
      facilitator: member && member.facilitator ? member.facilitator : "",
      image: member && member.image ? member.image : 0,
    });
  }, [member, pass, membersArr, groupID]);

  const {
    username,
    groupid,
    name,
    lastname,
    role,
    edulevel,
    email,
    instemail,
    phonenumber,
    linkedin,
    facilitator,
    image,
  } = memberData;

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState(password);

  const onChange = (e) => {
    setMemberData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const changePass = (e) => {
    setPassword(e.target.value);
  };

  const changeConfirm = (e) => {
    setConfirm(e.target.value);
  };

  const passCheck = () => {
    if (password !== confirm) return false;
    return true;
  };

  const passStrength = (password) => {
    const regex = /^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$/;
    return regex.test(password);
  };


  const uploadImage = async (base64EncodedImage) => {
    const splitImage = base64EncodedImage.split(",")[1];
    setLoading(true);
    try {
      await dispatch(
        uploadImageToBucket({
          id: id,
          groupid: groupID,
          name: name,
          lastname: lastname,
          image: splitImage,
        })
      ).unwrap();
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Error uploading image");
    }
  };

  const validateEmail = (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(email)) {
      return true;
    }
    return false;
  };

  const checkLetters = (word) => {
    if (/^\s*([0-9a-zA-Z. _-]*)\s*$/.test(word)) {
      return true;
    }
    return false;
  };

  console.log(memberData);

  console.log(previewSource);

  // Submit edit member
  const onSubmit = async (e) => {
    e.preventDefault();

    const canSave = [
      id,
      username,
      name,
      lastname,
      groupid,
      role,
      instemail,
    ].every((el) => el.length > 0);

    if (!canSave) {
      toast.error("Please fill in all the fields");
      return;
    }

    if (!checkLetters(username)) {
      return toast.error("Invalid characters in username");
    }

    if (!checkLetters(name)) {
      return toast.error("Invalid characters in name");
    }

    if (!checkLetters(lastname)) {
      return toast.error("Invalid characters in lastname");
    }

    if (!validateEmail(instemail)) {
      return toast.error("Please enter a valid email.");
    }

    const foundMember = allMembers.some(
      (member) => username !== initialUsername && member.username === username
    );

    if (foundMember) {
      toast.error("User already exists. Please change username");
      return;
    }

    if (canSave) {
      try {
        if (previewSource && previewSource.includes("data:image")) {
          await uploadImage(previewSource);
        } else if (image === 0 && previewSource !== 0) {
          await dispatch(
            deleteImageFromBucket({
              id: id,
              groupid: groupID,
            })
          ).unwrap();
        }
        await dispatch(
          editMember({
            id,
            username,
            name,
            lastname,
            groupid,
            role,
            email,
            edulevel,
            instemail,
            phonenumber,
            linkedin,
            facilitator,
            image,
          })
        ).unwrap();
        await dispatch(getMembers(groupid)).unwrap();
        await dispatch(getAll()).unwrap();
        dispatch(resetImage());
        toast.success("Member successfully updated");
      } catch (error) {
        const message =
          error.response.data.message || error.message || error.toString();
        toast.error(message);
        console.log(message);
      } finally {
        navigate(`/${groupID}`);
      }
    } else {
      toast.error("Please fill in all fields");
    }
  };

  const [hidden, setHidden] = useState(true);

  const onClick = (e) => {
    e.preventDefault();
    setHidden(!hidden);
  };
  const onCancel = () => {
    setHidden(true);
  };

  const submitPass = async (e) => {
    e.preventDefault();

    if (!passCheck()) {
      toast.error("passwords do not match");
      return;
    }

    if (!passStrength(password)) {
      toast.error(
        "password must contain at least 1 letter, number, and be at least 8 characters long"
      );
      return;
    }

    try {
      await dispatch(
        updatePass({
          id,
          password,
        })
      ).unwrap();
      dispatch(resetPass());
      toast.success("Password successfully updated");
      setHidden(true);
      await dispatch(getMembers(groupid)).unwrap();
      await dispatch(getAll()).unwrap();
      setMemberData((prevState) => ({ ...prevState, password: "" }));
    } catch (error) {
      const message =
        error.response.data.message || error.message || error.toString();
      toast.error(message);
      console.log(message);
    } finally {
      navigate(`/${groupID}`);
    }
  };

  if (member === undefined) {
    return (
      <Container>
        <Divider hidden />
        <Header as="h2" textAlign="center">
          Loading...
        </Header>
      </Container>
    );
  }

  const roleoptions = [
    { key: "guest", text: "Guest", value: "guest" },
    { key: "participant", text: "Participant", value: "participant" },
    { key: "alumni", text: "Alumni", value: "alumni" },
    { key: "facilitator", text: "Facilitator", value: "facilitator" },
  ];

  if (user.role === "admin") {
    roleoptions.push({ key: "admin", text: "Admin", value: "admin" });
  }

  return (
    <Container>
      <Divider hidden />
      <Header as="h2" textAlign="center">{`Edit ${member.name} ${member.lastname}`}</Header>
      <Segment raised loading={isLoading || loading} size="massive">
        <Form size="huge" onSubmit={onSubmit}>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              label="Name"
              placeholder="Name"
              name="name"
              value={name}
              onChange={onChange}
              required
            />
            <Form.Input
              fluid
              label="Last Name"
              placeholder="Last Name"
              name="lastname"
              value={lastname}
              onChange={onChange}
              required
            />
            <Form.Input
              fluid
              label="Username"
              placeholder="Username"
              name="username"
              value={username}
              onChange={onChange}
              required
            />
          </Form.Group>
          {
            user.role === "admin" && role !== "guest" && (
              <Form.Group widths="equal">
                <Form.Input
                  fluid
                  label="Password"
                  placeholder="Password"
                  type="password"
                  name="password"
                  value={password}
                  onChange={changePass}
                  required
                  disabled={hidden}
                />
                <Form.Input
                  fluid
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  type="password"
                  name="confirm"
                  value={confirm}
                  onChange={changeConfirm}
                  disabled={hidden}
                  required
                />
                {
                  hidden ? (
                    user.role !== "admin" ? (
                      <Popup
                        trigger={<Button compact size="big" type="button" disabled>Update Password</Button>}
                        content="Only admins can change the password."
                        open
                        position="left center"
                        flowing
                      />
                    ) : (
                      <Button compact size="big" type="button" color="blue" onClick={onClick}>Update Password</Button>
                    )
                  ) : (
                    <Button.Group>
                      <Button size="big" type="submit" color="green" onClick={submitPass}>Submit</Button>
                      <Button size="big" type="button" color="red" onClick={onCancel}>Cancel</Button>
                    </Button.Group>
                  )
                }
              </Form.Group>
            )
          }
          <Form.Group widths="equal">
            <Form.Dropdown
              fluid
              label="Role"
              selection
              options={roleoptions}
              name="role"
              value={role}
              onChange={(e, { value }) => setMemberData((prevState) => ({ ...prevState, role: value }))}
              required
            />
            <Form.Input
              fluid
              label="Group Name"
              placeholder="Enter a Group Name"
              name="groupid"
              value={groupid}
              readOnly
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              label="Email"
              placeholder="Email"
              type="email"
              name="email"
              value={email}
              onChange={onChange}
            />
            <Form.Input
              fluid
              label="Institutional Email"
              placeholder="Institutional Email"
              type="email"
              name="instemail"
              value={instemail}
              onChange={onChange}
              required
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              label="Phone Number"
              placeholder="xxx xxx xxxx"
              type="tel"
              name="phonenumber"
              value={phonenumber}
              onChange={onChange}
            />
            <Form.Input
              fluid
              label="LinkedIn"
              placeholder="LinkedIn"
              name="linkedin"
              value={linkedin}
              onChange={onChange}
            />
            <Form.Dropdown
              fluid
              label="Education Level"
              selection
              options={[
                { key: "Bsc", text: "Bachelor's Degree", value: "Bsc" },
                { key: "Msc", text: "Master's Degree", value: "Msc" },
                { key: "PhD", text: "PhD", value: "PhD" },
              ]}
              name="edulevel"
              value={edulevel}
              onChange={(e, { value }) => setMemberData((prevState) => ({ ...prevState, edulevel: value }))}
              required
            />
          </Form.Group>
          {/* {
            role === "participant" && (
              <Form.Input
                fluid
                label="Google Meet Link"
                placeholder="Google Meet Link"
                name="googleMeet"
                onChange={onChange}
              />
            )
          } */}
          <Form.Field>
            <label>Profile Picture</label>
            <DragDrop previewSource={previewSource} setPreviewSource={setPreviewSource} setMemberData={setMemberData} />
          </Form.Field>
          <Divider />
          <Form.Group widths="equal">
            <Form.Button fluid size="big" color="green" type="submit">
              Save
            </Form.Button>
            <Form.Button type="button" fluid size="big" color="red"  onClick={() => navigate(`/${groupID}`)}>
              Cancel
            </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
    </Container>
  );
};
export default MemberEdit;
